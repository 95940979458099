import { Addon } from 'types/addon.type';
import ErrorCodes from 'types/error-codes.enum';
import { ErrorMessage } from 'types/error-message.type';

export const defaultError: ErrorMessage = {
  code: ErrorCodes.frontendHell,
  message: 'Booking failed',
  reason: ''
};

export const getAddonPrice = (addon: Addon, rentalPeriod: number) => {
  let { price } = addon;
  if (addon.pricePer !== 'rental') {
    price *= rentalPeriod;

    if (addon.priceMin) {
      if (price < addon.priceMin) {
        price = addon.priceMin;
      }
    }

    if (addon.priceMax) {
      if (price > addon.priceMax) {
        price = addon.priceMax;
      }
    }
  }

  price *= addon.selection.length;
  return price;
};

import React, { FC, useState } from 'react';
import classNames from 'classnames';
import CarIcon from 'components/common/car-icon';
import Button from 'components/forms/button';
import dynamic from 'next/dynamic';

import { browserSide } from 'src/utils';
import useHasMounted from 'hooks/use-has-mounted';
import styles from './dev-tools.module.scss';

type Props = {
  title?: string;
  data: any;
  className?: string;
  expanded?: boolean;
};

const DynamicReactJson = dynamic(() => import('react-json-view'), { ssr: false });

const JsonPreview: FC<Props> = ({ title, data, className, expanded }) => {
  const mounted = useHasMounted();
  const [isExpanded, setExpanded] = useState(expanded);
  if (!mounted) return null;

  return (
    <div className={classNames(className, styles.jsonPreview)}>
      <div>
        <Button onClick={() => setExpanded(!isExpanded)}>
          <CarIcon icon="chevron-down" />
          <span>{title || 'Model data'}</span>
        </Button>
      </div>
      {isExpanded && data && browserSide() && (
        <div className={styles.jsonPreview__data}>
          <DynamicReactJson
            src={data}
            theme="monokai"
            enableClipboard={false}
            displayDataTypes={false}
          />
        </div>
      )}
    </div>
  );
};

JsonPreview.defaultProps = {
  title: undefined,
  className: undefined,
  expanded: false
};

export default JsonPreview;

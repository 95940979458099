import classNames from 'classnames';
import React, { FC, ReactNode, useState } from 'react';
import CarIcon from 'components/common/car-icon';
import BoxOpener from 'modules/booking-form/box-opener';

import styles from './common.module.scss';

export type LevelType = 'error' | 'info' | 'default' | 'warning' | 'success' | 'neutral';

type Props = {
  title?: string;
  icon?: string;
  level?: LevelType;
  children?: ReactNode;
  className?: string;
  showOpener?: boolean;
  isOpened?: boolean;
};

const Infobox: FC<Props> = ({
  title = '',
  icon = null,
  level = 'default',
  children = '',
  className = undefined,
  isOpened = true,
  showOpener = false
}) => {
  const [opened, setValue] = useState(isOpened);
  return (
    (children || title) && (
      <div className={classNames(styles.infobox, className, styles[`infobox__${level}`])}>
        {icon && <CarIcon size="18px" icon={icon} />}
        <div>
          {(title || showOpener) && (
            <div className={styles.titleSection}>
              {title && <h3 className={styles.infobox__title}>{title}</h3>}
              {showOpener && (
                <BoxOpener className={styles.box__opener} isOpened={opened} onClick={setValue} />
              )}
            </div>
          )}
          {children && (
            <div
              className={classNames(styles.infobox__content, {
                [styles.box__is_closed]: !opened
              })}
            >
              {children}
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default Infobox;

import { AutofillData } from 'types/booking/autofill-data.type';

const autofillData: AutofillData = {
  customer: {
    title: '',
    gender: 'MR',
    dob: '1985-01-01',
    fname: 'Hans',
    sname: 'Klenz',
    street: 'vilbeler landstr.',
    houseno: '203',
    postcode: '60388',
    city: 'Frankfurt am Main',
    state: '',
    country: 'DE',
    phone: '4961095050',
    email: 'car@ypsilon.net',
    fax: '',
    company: 'ypsilon.net ag'
  },
  payment: {
    usedPayment: 'CC',
    creditCard: {
      name: 'CC',
      cvc: '123',
      type: 'VI',
      number: '4111111111111111',
      numberObfuscated: '',
      expire: '2025-11-01',
      holder: 'Hans Klenz'
    }
  }
};

export default autofillData;

import React, { FC, useEffect, useState } from 'react';
import OverlayWrapper from 'components/common/overlay-wrapper';
import Button from 'components/forms/button';
import CarVariantsMap from 'modules/maps/car-variants-map';
import useOffersContext from 'providers/offers/use-offers-context';
import useTranslation from 'providers/translations/use-translations';
import { CarOffer } from 'src/cars/types';
import { goToBookingPage } from 'src/url-manager';
import { sameLocations } from 'src/utils';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';
import { wsInfo } from 'src/logger';
import styles from '../details.module.scss';
import CarVariant from './car-variant';

type Props = {
  offer: CarOffer;
};

const CarStationsTab: FC<Props> = ({ offer }) => {
  const { t } = useTranslation();
  const { inhouse } = useDevToolsContext();
  const { isMobile } = useScreenDetect();
  const { stations, searchData } = useOffersContext();
  const [highlight, setHighlight] = useState('');
  const [enlargeMap, setEnlargeMap] = useState(false);
  const [oneWaySearch] = useState(
    !sameLocations(searchData?.location?.pickUp, searchData?.location?.dropOff, true)
  );
  const items = offer.variants.concat(offer.otherVariants);

  useEffect(() => {
    wsInfo('userInteraction', 'Open car stations tab', {
      offerId: offer.offerId
    });
  }, []);

  return (
    <div className={styles.stationsTab}>
      <div className={styles.stationsTab__main}>
        <p>{t(oneWaySearch ? 'drel_pickup_loc' : '0nc_possible_stations')}</p>
        <ul className={styles.stationsTab__variantList} onMouseLeave={() => setHighlight('')}>
          <li
            className={styles.stationsTab__variant}
            onMouseEnter={() => setHighlight(offer.offerId)}
          >
            <CarVariant
              offer={offer}
              variant={{
                offerId: offer.offerId,
                dropoffStation: offer.dropoffStation,
                pickupStation: offer.pickupStation,
                price: offer.price
              }}
              current
              highlight={highlight === offer.offerId}
            />
          </li>
          {items
            .sort((a, b) => a.price - b.price)
            .map((variant) => (
              <li
                key={variant.offerId}
                className={styles.stationsTab__variant}
                onMouseEnter={() => setHighlight(variant.offerId)}
              >
                <CarVariant
                  offer={offer}
                  variant={variant}
                  highlight={highlight === variant.offerId}
                />
              </li>
            ))}
        </ul>
        {oneWaySearch && (
          <>
            <p>{t('drel_dropoff_loc')}</p>
            <ul className={styles.stationsTab__variantList}>
              <li
                className={styles.stationsTab__variant}
                onMouseEnter={() => setHighlight(offer.offerId)}
              >
                <CarVariant
                  offer={offer}
                  mode="dropoff"
                  variant={{
                    offerId: offer.offerId,
                    dropoffStation: offer.dropoffStation,
                    pickupStation: offer.pickupStation,
                    price: offer.price
                  }}
                  current
                  hideBtn
                />
              </li>
            </ul>
          </>
        )}
      </div>

      {!isMobile && (
        <div className={styles.stationsTab__map}>
          <OverlayWrapper
            open
            closeBtn
            inUse={enlargeMap}
            width="80%"
            title={t('0nc_possible_stations')}
            onClose={() => setEnlargeMap(false)}
          >
            <CarVariantsMap
              offer={offer}
              mainCode={offer.pickupStation.code}
              variants={offer.variants.concat(offer.otherVariants)}
              stations={stations.filter((el) => el.partner === offer.partner)}
              height={enlargeMap ? 500 : 300}
              highlightVariant={highlight}
              onChange={(id) => goToBookingPage(id, inhouse)}
              markerLabelOnHover={t('0nc_choosethis')}
            />
          </OverlayWrapper>
          <Button variant="primary" onClick={() => setEnlargeMap(true)}>
            {t('enlarge_map')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CarStationsTab;

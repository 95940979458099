import { useContext } from 'react';
import { bookingFormContext } from '.';
import { BookingFormContextType } from './types';

const useBookingFormContext = (): BookingFormContextType => {
  const {
    events,
    search,
    offer,
    stations,
    addons,
    extraFields,
    payments,
    terms,
    reloadOn,
    hasStateFieldRequired,
    norrisCfg,
    driverAge,
    driverCountry,
    pricing,
    setPricing,
    customerData,
    setCustomerData,
    driverData,
    setDriverData,
    selectedAddons,
    setSelectedAddons,
    selectedInsurances,
    updateSelectedInsurances,
    paymentData,
    setPaymentData,
    extraData,
    setExtraData,
    reprice,
    applyFormConfig,
    makeBooking,
    fillForm,
    fillEvents,
    calculationsEvents,
    termsOnDemand,
    addonsOnDemand,
    showDriverForm,
    updateTestMode,
    getBookingTerms,
    warnings
  } = useContext(bookingFormContext);

  return {
    events,
    search,
    offer,
    stations,
    addons,
    extraFields,
    payments,
    terms,
    reloadOn,
    hasStateFieldRequired,
    norrisCfg,
    driverAge,
    driverCountry,
    pricing,
    setPricing,
    customerData,
    setCustomerData,
    driverData,
    setDriverData,
    selectedAddons,
    setSelectedAddons,
    selectedInsurances,
    updateSelectedInsurances,
    paymentData,
    setPaymentData,
    extraData,
    setExtraData,
    reprice,
    applyFormConfig,
    makeBooking,
    fillForm,
    fillEvents,
    calculationsEvents,
    termsOnDemand,
    addonsOnDemand,
    showDriverForm,
    updateTestMode,
    getBookingTerms,
    warnings
  };
};

export default useBookingFormContext;

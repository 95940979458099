import React, { FC } from 'react';

import { StationType } from 'src/stations/types';
import CarIcon from 'components/common/car-icon';
import { stationTypesToIcon } from 'src/stations/station-tools';
import classNames from 'classnames';
import styles from '../stations-map.module.scss';
import { PigeonProps } from '../types';

interface Props extends PigeonProps {
  locationTypes: Array<StationType>;
  priceDiff: number;
}

const CarDotMarker: FC<Props> = (props) => (
  <div
    className={classNames(styles.carDotVariantMarker, {
      [styles['carDotVariantMarker--green']]: props.priceDiff < 0,
      [styles['carDotVariantMarker--red']]: props.priceDiff > 0
    })}
    style={{
      left: `${props.left}px`,
      top: `${props.top}px`
    }}
  >
    <CarIcon
      icon={stationTypesToIcon(props.locationTypes)}
      size="10px"
      className={styles.carDotVariantMarker__icon}
    />
  </div>
);

CarDotMarker.defaultProps = {};

export default CarDotMarker;

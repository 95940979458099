import classNames from 'classnames';
import CarIcon from 'components/common/car-icon';
import React, { FC } from 'react';
import { stationTypesToIcon } from 'src/stations/station-tools';
import { Station } from 'src/stations/types';
import styles from '../details.module.scss';

type Props = {
  station: Station;
  oneway?: boolean;
  icon?: string;
  current?: boolean;
};

const VariantStationInfo: FC<Props> = ({
  station,
  oneway = false,
  icon = undefined,
  current = false
}) => (
  <div className={styles.stationsTab__station}>
    <div className={styles.stationsTab__station__main}>
      {icon && (
        <CarIcon
          className={classNames(styles.stationsTab__station__icon, {
            [styles['stationsTab__station__icon--current']]: current
          })}
          icon={oneway ? icon : stationTypesToIcon(station.locationTypes)}
          size="22px"
        />
      )}

      <div>
        <p className={styles.stationsTab__station__name}>{station.name}</p>
        <span className={styles.stationsTab__station__address}>
          {`${station.city}, ${station.addressLines.join(', ')}`}
        </span>
      </div>
    </div>

    {oneway && (
      <CarIcon
        icon={stationTypesToIcon(station.locationTypes)}
        size="24px"
        className={styles.stationsTab__variant__icon}
      />
    )}
  </div>
);

export default VariantStationInfo;

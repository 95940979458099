import { AxiosResponse } from 'axios';
import { Subject } from 'rxjs';
import { get } from 'lodash';
import { BookingEngineEvent, ScaAuthParams } from 'src/booking-engine/types';
import ErrorCodes from 'types/error-codes.enum';
import FrontendError, { simplifyFrontendError } from 'src/frontend-error.class';
import { wsError, wsInfo, wsWarning } from 'src/logger';
import axiosInstance from 'src/axios-instance';
import { ScaConfig } from './types';

declare let scaClient: any;

const SCA_WRAPPER_ID = 'yps_sca_wrap';
const SCA_CHECK_TIMEOUT = 100;

const getScaScript = (data: ScaAuthParams): Promise<ScaConfig> =>
  new Promise((resolve, reject) => {
    axiosInstance.post('/proxy/sca/auth', data).then((res: AxiosResponse) => {
      resolve(res.data);
    }, reject);
  });

export const scaReady = (): Promise<void> => {
  if (typeof scaClient !== 'undefined') return Promise.resolve();

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      scaReady().then(resolve, reject);
    }, SCA_CHECK_TIMEOUT);
  });
};

const initScaClient = (stream: Subject<BookingEngineEvent>) => {
  scaClient.init({
    wrapId: SCA_WRAPPER_ID,
    onChallengeCallback: () => {
      stream.next({
        type: 'scaChallenge'
      });
      wsInfo('userInteraction', 'SCA challenge');
    },
    onFinishedCallback: (data) => {
      if (get(data, 'success', false)) {
        stream.next({
          type: 'scaVerified',
          data: { scaResult: data }
        });
        stream.complete();
        wsInfo('scaProcess', 'SCA finished', data);
      } else if (get(data, 'status') === 'NOTPERFORMED') {
        stream.error(new FrontendError([{ code: ErrorCodes.scaNotPerformed, message: '' }]));
        wsWarning('scaProcess', 'SCA not performed', data);
      } else {
        const message = get(data, 'details');
        stream.error(
          new FrontendError([
            {
              code: ErrorCodes.scaNotAuthenticated,
              message:
                message !== 'Unknown error' ? String(message).replace('cardholderInfo: ', '') : ''
            }
          ])
        );
        wsWarning('scaProcess', `SCA failed ${get(data, 'status')}`, data);
      }
    }
  });
};

const scaValidate = (data: ScaAuthParams): Subject<BookingEngineEvent> => {
  const stream = new Subject<BookingEngineEvent>();
  if (typeof scaClient !== 'undefined') {
    scaClient = undefined;
  }

  if (data) {
    getScaScript(data).then(
      (cfg) => {
        if (!cfg) {
          stream.error(new FrontendError([{ code: ErrorCodes.scaScriptError, message: '' }]));
          wsError('scaProcess', 'SCA auth request - missing config');
          return;
        }
        stream.next({
          type: 'scaInit',
          data: { scaConfig: cfg }
        });

        wsInfo('scaProcess', 'Fetching SCA script');

        scaReady().then(
          () => {
            initScaClient(stream);
            wsInfo('scaProcess', 'SCA script loaded');
          },
          () => {
            stream.error(new FrontendError([{ code: ErrorCodes.scaScriptError, message: '' }]));
            wsError('scaProcess', 'SCA script initialization failed');
          }
        );
      },
      (err: FrontendError) => {
        stream.error(
          new FrontendError([{ code: err?.code ?? ErrorCodes.scaScriptError, message: '' }])
        );
        wsError('scaProcess', 'SCA auth request failed', simplifyFrontendError(err), err.requestId);
      }
    );
  } else {
    stream.complete();
  }

  return stream;
};

export default scaValidate;

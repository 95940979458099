import React, { FC, memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import CarIcon from 'components/common/car-icon';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import styles from '../header.module.scss';

interface Props {
  nr: number;
  label: string;
  initialCurrent: number;
  disabled?: boolean;
  onClick?: () => void;
}

const SingleStep: FC<Props> = ({
  nr,
  label,
  initialCurrent,
  onClick = () => null,
  disabled = false
}) => {
  const { isMobile } = useScreenDetect();
  const [hover, setHover] = useState(false);
  const [current, setCurrent] = useState(initialCurrent === nr);
  const [past, setPast] = useState(initialCurrent > nr);

  const btnClick = () => {
    if (!past || disabled) return;
    onClick();
  };

  useEffect(() => {
    setCurrent(initialCurrent === nr);
    setPast(initialCurrent > nr);
  }, [nr, label]);

  return (
    <li className={classNames(styles.steps__item)}>
      <button
        type="button"
        className={classNames(styles.steps__item__btn, {
          [styles['steps__item__btn--highlight']]: current || (hover && past)
        })}
        onClick={btnClick}
        style={{
          cursor: past ? 'pointer' : undefined
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {past ? (
          <CarIcon
            icon="tick"
            size="12px"
            className={classNames(styles.steps__icon, {
              [styles['steps__icon--highlight']]: current || (hover && past)
            })}
          />
        ) : (
          <span
            className={classNames(styles.steps__number, {
              [styles['steps__number--highlight']]: current || (hover && past)
            })}
          >
            {nr}
          </span>
        )}
        {(!isMobile || current) && <span>{label}</span>}
      </button>
    </li>
  );
};

export default memo(SingleStep);

import React, { FC, ReactNode } from 'react';
import CarIcon from 'components/common/car-icon';
import styles from './details.module.scss';

interface Props {
  legend?: string;
  label?: string;
  labelIcon?: string;
  content?: string;
  contentIcon?: string;
  description?: string;
  descriptionMinor?: string;
  children?: ReactNode;
}

const CarEquipmentItem: FC<Props> = ({
  legend = '',
  label = '',
  labelIcon = '',
  content = '',
  contentIcon = '',
  description = '',
  descriptionMinor = '',
  children = null
}) => (
  <>
    {legend && <span className={styles.carDetails__legend}>{legend}</span>}
    {(label || content) && (
      <>
        <span className={styles.carDetails__item}>
          {label && (
            <span className={styles.carDetails__item_label}>
              {labelIcon && <CarIcon icon={labelIcon} size="18px" />}
              {label}
            </span>
          )}

          {(content || contentIcon) && (
            <span className={styles.carDetails__item_value}>
              {contentIcon && <CarIcon icon={contentIcon} size="12px" />}
              {content}
            </span>
          )}
        </span>
      </>
    )}
    {children}
    {description && (
      <span
        className={styles.carDetails__description}
        dangerouslySetInnerHTML={{
          __html: `${description}
            <span className=${styles.carDetails__description_minor}>
              ${descriptionMinor}
            </span>`
        }}
      />
    )}
  </>
);

export default CarEquipmentItem;

import React, { ReactElement, useState } from 'react';
import Button from 'components/forms/button';
import classNames from 'classnames';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import styles from './common.module.scss';

interface TabsProps {
  children: Array<ReactElement>;
  className?: string;
}

const Tabs: React.FC<TabsProps> = ({ children, className = '' }) => {
  const [current, setCurrent] = useState(0);

  return (
    <div className={styles.tabs}>
      <ul
        className={classNames(styles.tabs__list, className)}
        style={{
          gridTemplateColumns: children
            .filter((el) => typeof el === 'object')
            .map(() => 'auto')
            .join(' ')
        }}
      >
        {children
          .filter((el) => typeof el === 'object')
          .map(
            (item: ReactElement, i: number) =>
              !item.props['data-hide'] && (
                <li key={i}>
                  <Button
                    text={item.props['data-tab']}
                    disabled={item.props['data-disabled']}
                    variant={current === i ? 'primary' : 'secondary'}
                    isActive={current === i}
                    onClick={() => {
                      setCurrent(i);
                    }}
                  />
                </li>
              )
          )}
      </ul>

      <SwitchTransition mode="out-in">
        <CSSTransition<undefined>
          key={current}
          addEndListener={(node: HTMLElement, done: () => void) => {
            node.addEventListener('transitionend', done, false);
          }}
          classNames="tabsSlide"
        >
          <div className={styles.tabs__content}>
            {children.filter((el) => typeof el === 'object')[current].props.children}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default Tabs;

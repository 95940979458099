import React from 'react';
import classNames from 'classnames';

import useCarsApi from 'providers/cars-api/use-cars-api';
import Steps from './steps';
import CountrySelect from './country-select';
import LanguageSelect from './language-select';

import styles from './header.module.scss';

const PageHeader = (): any => {
  const { subpage } = useCarsApi();

  return (
    <div
      className={classNames(styles.header, 'header__page-header', {
        [styles['header--enlarged']]: subpage === 'searchform',
        [styles['header--booking']]: subpage === 'bookingForm'
      })}
    >
      {(subpage === 'offerlist' || subpage === 'searchform') && (
        <div className={classNames(styles.header__bar, 'header_header__bar')}>
          <CountrySelect />
          <LanguageSelect />
        </div>
      )}

      <Steps />
    </div>
  );
};
export default PageHeader;

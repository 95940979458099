import React, { FC, useEffect } from 'react';
import { CarOffer } from 'src/cars/types';
import classNames from 'classnames';
import InsuranceOverview from 'modules/offer/insurance-overview';

import styles from 'modules/offer/offer.module.scss';
import { wsInfo } from 'src/logger';

interface Props {
  offer: CarOffer;
}

const CarInsurancesTab: FC<Props> = ({ offer }) => {
  useEffect(() => {
    wsInfo('userInteraction', 'Open car insurances tab', {
      offerId: offer.offerId
    });
  }, []);

  return (
    <InsuranceOverview
      offer={offer}
      short={false}
      className={classNames(styles.offerInsurances, styles.offerInsurances__short)}
    />
  );
};

export default CarInsurancesTab;

import React, { FC, useState } from 'react';
import Button from 'components/forms/button';
import useTranslation from 'providers/translations/use-translations';
import CarIcon from 'components/common/car-icon';
import { useReactToPrint } from 'react-to-print';
import classNames from 'classnames';
import styles from './print.module.scss';

interface Props {
  printTargetRef: any;
}

const PrintWrapper: FC<Props> = ({ printTargetRef }) => {
  const [printView] = useState(false);
  const printHandle = useReactToPrint({
    content: () => printTargetRef.current,
    bodyClass: 'printOffer'
  });
  const { t } = useTranslation();

  return (
    <>
      {printView !== true && (
        <Button
          className={classNames(styles.printButton, 'printButton', 'noPrint')}
          variant="secondary"
          size="medium"
          onClick={printHandle}
        >
          <CarIcon size="20" icon="print" />
          {t('print_this_page')}
        </Button>
      )}
    </>
  );
};

export default PrintWrapper;

import useHasMounted from 'hooks/use-has-mounted';
import ReactDOM from 'react-dom';
import React, { FC, createContext, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import useState from 'react-usestateref';
import BookingEngine from 'src/booking-engine';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';
import {
  BookingEngineEvent,
  BookingPaymentData,
  BookingPersonalData,
  BookingPricing,
  BookingResult,
  BookingTerms,
  CalculationsEvent,
  ExtraField,
  FormConfigData,
  PaymentOption,
  SelectedAddons,
  SelectedInsurances
} from 'src/booking-engine/types';
import { CarOffer } from 'src/cars/types';
import { serverSide, browserSide } from 'src/utils';
import { CarTermsRequestParams } from 'types/car-terms.type';
import { Addon } from 'types/addon.type';
import { NorrisConfig } from 'src/norris/types';
import { Subject } from 'rxjs';
import { AutofillData } from 'types/booking/autofill-data.type';
import { normalizeAutofillData } from 'src/utils/data-conversion';
import { carFromFormConfig, getCarTerms } from 'src/cars/car-tools';
import { Station } from 'src/stations/types';
import { normalizeStations } from 'src/stations/station-tools';
import { goToOfferlist, updateSearchParam } from 'src/url-manager';
import { TestMode } from 'types/engine/dev-data.type';
import BrowserStorage from 'src/browser-storage';
import { SearchData } from 'types/search/search-data.type';
import axiosInstance from 'src/axios-instance';
import ExchangeOffice from 'components/common/exchange-office.class';
import {
  defaultBookingPricing,
  defaultCustomerData,
  defaultPaymentData,
  formConfigToSearch,
  getDefaultCustomerData,
  prepareTerms
} from './helpers';
import { BookingFormContextProps, BookingFormContextType } from './types';

const bookingFormContext = createContext<BookingFormContextType>({
  events: null,
  search: null,
  offer: null,
  stations: [],
  addons: [],
  extraFields: [],
  payments: [],
  terms: null,
  reloadOn: [],
  hasStateFieldRequired: false,
  norrisCfg: null,

  driverAge: 0,
  driverCountry: '',
  pricing: defaultBookingPricing,
  setPricing: () => null,
  customerData: defaultCustomerData,
  setCustomerData: () => null,
  driverData: defaultCustomerData,
  setDriverData: () => null,
  selectedAddons: [],
  setSelectedAddons: () => null,
  selectedInsurances: [],
  updateSelectedInsurances: () => null,
  paymentData: defaultPaymentData,
  setPaymentData: () => null,
  extraData: {},
  setExtraData: () => null,

  reprice: () => null,
  applyFormConfig: () => null,
  makeBooking: () => null,
  fillForm: () => null,
  fillEvents: null,
  calculationsEvents: null,
  termsOnDemand: null,
  addonsOnDemand: null,
  showDriverForm: true,
  updateTestMode: () => null,
  getBookingTerms: () => null,
  warnings: []
});

const BookingFormProvider: FC<BookingFormContextProps> = ({
  children,
  general,
  agent,
  searchId,
  offerId,
  autofillData,
  testMode,
  initialSearch
}) => {
  const mounted = useHasMounted();
  const { inhouse, customProxy } = useDevToolsContext();
  const [events] = useState<Subject<BookingEngineEvent>>(new Subject<BookingEngineEvent>());
  const [fillEvents] = useState<Subject<AutofillData>>(new Subject<AutofillData>());
  const [calculationsEvents] = useState<Subject<CalculationsEvent>>(
    new Subject<CalculationsEvent>()
  );

  const [bookingEngine, setBookingEngine] = useState<BookingEngine>(null);
  const [search, setSearch] = useState<SearchData>();
  const [offer, setOffer, offerRef] = useState<CarOffer>(null);
  const [stations, setStations] = useState<Array<Station>>(null);
  const [addons, setAddons] = useState<Array<Addon>>([]);
  const [extraFields, setExtraFields] = useState<Array<ExtraField>>([]);
  const [payments, setPayments] = useState<Array<PaymentOption>>([]);
  const [terms, setTerms] = useState<BookingTerms>(null);
  const [hasStateFieldRequired, setHasStateFieldRequired] = useState(false);
  const [reloadOn, setReloadOn] = useState<Array<string>>([]);
  const [norrisCfg, setNorrisCfg] = useState<NorrisConfig>(null);
  const [termsOnDemand, setTermsOnDemand] = useState(false);
  const [addonsOnDemand, setAddonsOnDemand] = useState(false);
  const [showDriverForm, setShowDriverForm] = useState(false);
  const [warnings, setWarnings] = useState<Array<any>>([]);

  // Booking data
  const [driverAge, setDriverAge] = useState<number>(initialSearch?.driverAge);
  const [driverCountry, setDriverCountry] = useState<string>(general.cor);
  const [pricing, setPricing] = useState<BookingPricing>(defaultBookingPricing);
  const [customerData, setCustomerData] = useState<BookingPersonalData>(
    getDefaultCustomerData(general.cor)
  );
  const [driverData, setDriverData] = useState<BookingPersonalData>(getDefaultCustomerData());
  const [selectedAddons, setSelectedAddons] = useState<Array<SelectedAddons>>([]);
  const [selectedInsurances, updateSelectedInsurances] = useState<Array<SelectedInsurances>>([]);
  const [paymentData, setPaymentData] = useState<BookingPaymentData>(defaultPaymentData);
  const [extraData, setExtraData] = useState({});
  const [partnerHasSCA, setPartnerHasSCA] = useState(false);

  const { disableSca } = useDevToolsContext();

  const makeBooking = (): Promise<BookingResult> =>
    bookingEngine.processBooking(
      cloneDeep({
        offer,
        customer: customerData,
        driver: driverData,
        payment: paymentData,
        addons: selectedAddons,
        insurances: selectedInsurances,
        extras: extraData,
        locale: general.locale,
        dev: {
          disableSca
        },
        hasSCA: partnerHasSCA
      }),
      customProxy
    );

  const getBookingTerms = async (freshOffer) => {
    const postData: CarTermsRequestParams = {
      sessionId: searchId,
      offerId: freshOffer.offerId,
      productId: freshOffer.products.length ? freshOffer.products[0].productId : '',
      pickupStation: freshOffer.pickupStation.code,
      driverAge,
      driverCountry,
      locale: general.locale
    };

    const result = await getCarTerms(freshOffer, postData, undefined, customProxy).then(
      (response) => {
        if (response) {
          setTerms(response);
          return response;
        }
        setTerms({});
        return null;
      },
      () => {
        setTerms({});
        return null;
      }
    );
    return Promise.resolve(result);
  };

  const getBookingAddons = async (freshOffer) => {
    const postData: CarTermsRequestParams = {
      sessionId: searchId,
      offerId: freshOffer.offerId,
      productId: freshOffer.products.length ? freshOffer.products[0].productId : '',
      pickupStation: freshOffer.pickupStation.code,
      driverAge,
      driverCountry,
      locale: general.locale
    };

    axiosInstance.post('/proxy/addons', postData).then(
      (response) => {
        const oldExchangeRates = BrowserStorage.getObject('exchange-office');
        if (response.data.exchangeRates) {
          const newExchangeRates = {
            agentCurrency: oldExchangeRates.agentCurrency,
            rates: {
              ...oldExchangeRates.rates,
              ...response.data.exchangeRates
            }
          };

          BrowserStorage.setObject('exchange-office', newExchangeRates);
        }

        const ExOffice = new ExchangeOffice(response.data.exchangeRates || oldExchangeRates);
        const agentCurrency = ExOffice.getAgentCurrency();
        const newAddons = response.data.addons.map((addon) => {
          if (addon.price && addon.currency) {
            return {
              ...addon,
              priceExchanged: ExOffice.getPriceInAgentCurrency(addon.price, addon.currency),
              agentCurrency
            };
          }
          return {
            ...addon
          };
        });

        setAddons(newAddons);
      },
      () => setAddons([])
    );
    return Promise.resolve();
  };

  const applyFormConfig = async (data: FormConfigData) => {
    const stationsData = normalizeStations(data.stations);
    const searchData: SearchData = await formConfigToSearch(data, agent.agentId, customProxy);
    ReactDOM.unstable_batchedUpdates(() => {
      setSearch(searchData);
      setOffer(carFromFormConfig(data, searchData.rentDays, agent.currency, stationsData));
      setStations(stationsData);
      if (data.addonsOnDemand === true) {
        getBookingAddons(offerRef.current);
      } else {
        setAddons(data.offer.addons);
      }
      setExtraFields(data.extraFields);
      setPayments(data.payments);
      setTermsOnDemand(data.termsOnDemand);
      setAddonsOnDemand(data.addonsOnDemand);
      setShowDriverForm(data.showDriverForm);
      setReloadOn(data.reloadOn);
      setHasStateFieldRequired(data.hasStateFieldRequired);
      setNorrisCfg(data.norris);
      setPartnerHasSCA(data.hasSCA);
      updateSearchParam('oid', data.offer.offerId);
      if (data.termsOnDemand === true) {
        getBookingTerms(offerRef.current);
      } else {
        setTerms(prepareTerms(data.terms, general.locale));
      }
      setWarnings(data.warnings);
    });
  };

  const reprice = (data: any): Promise<FormConfigData> =>
    bookingEngine.loadFormConfig(data, true, customProxy);

  const fillForm = (data?: AutofillData) => {
    if (!data && !autofillData) return;
    fillEvents.next(data || normalizeAutofillData(autofillData));
  };

  const updateTestMode = (mode: TestMode) => {
    if (!bookingEngine) return;
    bookingEngine.testMode = mode;
  };

  // Booking engine initialization
  useEffect(() => {
    if (serverSide()) return () => null;

    if (!bookingEngine && browserSide()) {
      const engine = new BookingEngine({
        searchId,
        offerId,
        driverAge,
        driverCountry,
        testMode,
        locale: general.locale
      });
      engine.loadFormConfig({}, false, customProxy).then(
        (data) => {
          applyFormConfig(data);
        },
        (err) => {
          goToOfferlist(undefined, false, inhouse);
          events.error(err);
        }
      );
      setBookingEngine(engine);
    }
    return () => null;
  }, [mounted]);

  // Handle booking events
  useEffect(() => {
    if (!bookingEngine) return () => null;
    const stream = bookingEngine.events.subscribe(
      (e) => {
        if (!e) return;
        events.next(e);
      },
      (e) => {
        // console.log('booking error', e);
      }
    );

    return () => {
      stream.unsubscribe();
    };
  }, [bookingEngine, events]);

  return (
    <bookingFormContext.Provider
      value={{
        events,
        search,
        offer,
        stations,
        addons,
        extraFields,
        payments,
        terms,
        reloadOn,
        hasStateFieldRequired,
        norrisCfg,
        driverAge,
        driverCountry,
        pricing,
        setPricing,
        customerData,
        setCustomerData,
        driverData,
        setDriverData,
        selectedAddons,
        setSelectedAddons,
        selectedInsurances,
        updateSelectedInsurances,
        paymentData,
        setPaymentData,
        extraData,
        setExtraData,
        reprice,
        applyFormConfig,
        makeBooking,
        fillForm,
        fillEvents,
        calculationsEvents,
        termsOnDemand,
        getBookingTerms,
        addonsOnDemand,
        showDriverForm,
        updateTestMode,
        warnings
      }}
    >
      {children}
    </bookingFormContext.Provider>
  );
};

export { bookingFormContext, BookingFormProvider };

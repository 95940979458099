import React from 'react';
import useCarsApi from 'providers/cars-api/use-cars-api';
import useTranslation from 'providers/translations/use-translations';
import SubpageType from 'types/engine/subpage-type.type';
import { backToBookingForm, goToOfferlist } from 'src/url-manager';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';
import SingleStep from './single-step';

import styles from '../header.module.scss';

const stepToNumber = (step: SubpageType): number => {
  switch (step) {
    case 'searchform':
    case 'offerlist':
      return 1;
    case 'bookingForm':
      return 2;
    case 'confirmation':
      return 3;
    default:
      return 0;
  }
};

const Steps = () => {
  const { subpage } = useCarsApi();
  const { inhouse } = useDevToolsContext();
  const { t } = useTranslation();

  const step = stepToNumber(subpage);

  if (step < 2) return null;

  return (
    <>
      <ul className={styles.steps}>
        <SingleStep
          nr={1}
          label={t('top_offer')}
          initialCurrent={step}
          onClick={() => goToOfferlist(undefined, subpage === 'confirmation', inhouse)}
        />
        <SingleStep
          nr={2}
          label={t('top_booking')}
          initialCurrent={step}
          disabled={subpage === 'confirmation'}
          onClick={() => backToBookingForm(inhouse)}
        />
        <SingleStep nr={3} label={t('top_confirm')} initialCurrent={step} />
      </ul>
    </>
  );
};

export default Steps;

import IcoLabel from 'components/common/ico-label';
import React, { FC, memo } from 'react';
import useTranslation from 'providers/translations/use-translations';
import { StationType } from 'src/stations/types';
import { stationTypeMap } from 'src/stations/station-tools';

type Props = {
  types: Array<StationType>;
};
const LocationLabel: FC<Props> = ({ types }) => {
  const { t } = useTranslation();
  if (!types) return null;

  if (types.includes('terminal')) {
    return (
      <IcoLabel
        title={t(stationTypeMap.terminal.textId)}
        subtitle={t('station_location')}
        icon={stationTypeMap.terminal.icon}
      />
    );
  }

  if (types.includes('shuttle')) {
    return (
      <IcoLabel
        title={t(stationTypeMap.shuttle.textId)}
        subtitle={t('station_location')}
        icon={stationTypeMap.shuttle.icon}
      />
    );
  }

  if (types.includes('airport')) {
    return (
      <IcoLabel
        title={t(stationTypeMap.airport.textId)}
        subtitle={t('station_location')}
        icon={stationTypeMap.airport.icon}
      />
    );
  }

  if (types.includes('rail')) {
    return (
      <IcoLabel
        title={t(stationTypeMap.rail.textId)}
        subtitle={t('station_location')}
        icon={stationTypeMap.rail.icon}
      />
    );
  }

  if (types.includes('city')) {
    return (
      <IcoLabel
        title={t(stationTypeMap.city.textId)}
        subtitle={t('station_location')}
        icon={stationTypeMap.city.icon}
      />
    );
  }
  return null;
};

export default memo(LocationLabel);

import React, { FC, useEffect, useRef, useState } from 'react';
import { Map, Point } from 'pigeon-maps';
import { get } from 'lodash';
import { CarOffer, CarVariant } from 'src/cars/types';
import { coordinatesToLatLong } from 'src/utils/data-conversion';
import { findStationByCode } from 'src/stations/station-tools';
import { Station } from 'src/stations/types';
import classNames from 'classnames';
import CarPriceMarker from './car-price-marker';
import { Coordinates, FitMapResult } from '../types';
import { fitMap } from '../tools';
import CarDotMarker from './car-dot-marker';
import styles from '../stations-map.module.scss';

type Props = {
  initialCenter?: Coordinates;
  className?: string;
  title?: string;
  offer: CarOffer;
  mainCode: string;
  variants: Array<CarVariant>;
  stations: Array<Station>;
  height?: number;
  simplified?: boolean;
  highlightVariant?: string;
  mode?: 'pickup' | 'dropoff';
  onChange?: (id: string) => void;
  onVariantHover?: (id: string) => void;
  markerLabelOnHover?: string;
};

const CarVariantsMap: FC<Props> = ({
  offer,
  mainCode,
  variants,
  stations,
  title = undefined,
  height = 500,
  simplified = false,
  className = undefined,
  mode = 'pickup',
  highlightVariant = undefined,
  onChange = () => null,
  onVariantHover = () => null,
  markerLabelOnHover = undefined,
  initialCenter = undefined
}) => {
  const mapRef = useRef(null);
  const search = {}; // TODO - dynamic value
  const [ready, setReady] = useState(false);
  const [mainMarker, setMainMarker] = useState<any>();
  const [markers, setMarkers] = useState([]);
  const [zoom, setZoom] = useState<number>(11);
  const [center, setCenter] = useState<Point>(
    coordinatesToLatLong(initialCenter || get(search, 'location.pickUp.center'))
  );

  const stationKey = mode === 'pickup' ? 'pickupStation' : 'dropoffStation';

  useEffect(() => {
    const mainStation = findStationByCode(
      mainCode,
      stations.filter((item) => item.geo)
    );

    if (!mainStation) {
      setMainMarker(undefined);
      setMarkers([]);
      return;
    }

    const items = [];
    setMainMarker({
      id: offer.offerId,
      locationTypes: mainStation.locationTypes,
      price: 0,
      geo: coordinatesToLatLong(mainStation.geo)
    });

    variants
      .filter((item) => item.pickupStation.geo && item.dropoffStation.geo)
      .forEach((el) => {
        // if (!el[stationKey] || el[stationKey].geo.length < 2) return;
        items.push({
          id: el.offerId,
          locationTypes: el[stationKey].locationTypes,
          price: el.price - offer.price,
          geo: coordinatesToLatLong(el[stationKey].geo)
        });
      });
    setMarkers(items);

    const data: FitMapResult = fitMap(
      [coordinatesToLatLong(mainStation.geo)].concat(items.map((el) => el.geo)),
      get(mapRef, 'current.offsetWidth', 0),
      height
    );

    if (data) {
      setZoom(data.zoom);
      setCenter(data.center);
    }
    if (!ready) setReady(true);
  }, [variants, stations, mainCode]);

  const tilerProvider = (x, y, z) => {
    const s = String.fromCharCode(97 + ((x + y + z) % 3));
    return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`;
  };
  return (
    <div
      className={styles.carVariantsMap}
      style={{ gridTemplateRows: title ? '20px 1fr' : undefined }}
    >
      {title && <h4 className={styles.carVariantsMap__title}>{title}</h4>}
      <div className={classNames(styles.carVariantsMap__mapWrapper, className)} ref={mapRef}>
        <Map height={height} center={center} zoom={zoom} provider={tilerProvider}>
          {ready && mainMarker && (
            <CarPriceMarker
              key={mainMarker.id}
              locationTypes={mainMarker.locationTypes}
              anchor={mainMarker.geo}
              price={offer.price}
              currency={offer.currency}
              onClick={() => onChange(mainMarker.id)}
              current
              highlight={highlightVariant === mainMarker.id}
              zIndex={highlightVariant === mainMarker.id ? 100 : 10}
              markerLabelOnHover={markerLabelOnHover}
              onHoverChange={(e) => onVariantHover(e ? mainMarker.id : '')}
            />
          )}

          {ready &&
            !simplified &&
            markers.map((item) => (
              <CarPriceMarker
                key={item.id}
                locationTypes={item.locationTypes}
                anchor={item.geo}
                priceDiff={item.price}
                currency={offer.currency}
                onClick={() => onChange(item.id)}
                highlight={highlightVariant === item.id}
                zIndex={highlightVariant === item.id ? 100 : 5}
                markerLabelOnHover={markerLabelOnHover}
                onHoverChange={(e) => onVariantHover(e ? item.id : '')}
              />
            ))}

          {ready &&
            simplified &&
            markers.map((item) => (
              <CarDotMarker
                key={item.id}
                locationTypes={item.locationTypes}
                anchor={[item.geo[1], item.geo[0]]}
                priceDiff={item.price}
              />
            ))}
        </Map>
      </div>
    </div>
  );
};

export default CarVariantsMap;

import React, { FC, memo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { CarOffer } from 'src/cars/types';
import CarIcon from 'components/common/car-icon';
import classNames from 'classnames';
import useTranslation from 'providers/translations/use-translations';

import stylesTooltip from 'components/common/react-tooltip-common.module.scss';
import style from './offer.module.scss';

type Props = {
  offer: CarOffer;
  className?: string;
};

const ScoreLabel: FC<Props> = ({ offer, className = '' }) => {
  const { t } = useTranslation();

  const getPopupContent = () =>
    ReactDOMServer.renderToStaticMarkup(
      <div className={stylesTooltip.scoringDetails}>
        <div className={stylesTooltip.scoringDetails__label_main}>{t('drel_comparison')}</div>
        <div className={stylesTooltip.scoringDetails__content}>
          <div>
            <span
              className={classNames(
                stylesTooltip.scoringDetails__label,
                stylesTooltip.scoringDetails__label_basic
              )}
            >
              {t('basic_offer')}
            </span>
            <span className={stylesTooltip.scoringDetails__description}>
              <span className={stylesTooltip.scoringDetails__description_item}>
                <CarIcon size="18px" icon="check" style={{ color: 'var(--yps-success-color)' }} />
                {`${t('insurance_cdw_short')} ${t('insurance_with_excess')}`}
              </span>
            </span>
          </div>
          <div>
            <span
              className={classNames(
                stylesTooltip.scoringDetails__label,
                stylesTooltip.scoringDetails__label_economy
              )}
            >
              {t('economy_offer')}
            </span>
            <span className={stylesTooltip.scoringDetails__description_item}>
              <CarIcon size="18px" icon="check" style={{ color: 'var(--yps-success-color)' }} />
              {`${t('0nc_insurances')} ${t('insurance_without_excess')} ${t('pickup_or')} ${t(
                '0nc_excess_refund'
              )}`}
            </span>
          </div>
          <div>
            <span
              className={classNames(
                stylesTooltip.scoringDetails__label,
                stylesTooltip.scoringDetails__label_premium
              )}
            >
              {t('premium_offer')}
            </span>
            <span className={stylesTooltip.scoringDetails__description_item}>
              <CarIcon size="18px" icon="check" style={{ color: 'var(--yps-success-color)' }} />
              {`${t('0nc_insurances')} ${t('insurance_without_excess')} ${t('pickup_or')} ${t(
                '0nc_excess_refund'
              )}`}
            </span>
            <span className={stylesTooltip.scoringDetails__description_item}>
              <CarIcon size="18px" icon="check" style={{ color: 'var(--yps-success-color)' }} />
              {`${t('0nc_insurances')} ${t('insurance_short_glass')} ${t('insurance_tyres')} ${t(
                'insurance_short_roof'
              )}`}
            </span>
          </div>
        </div>
      </div>
    );

  return (
    <div
      className={classNames('score_label', style.scoreLabel, className, style[offer.scoreLabel])}
      data-html
      data-tooltip-html={getPopupContent()}
      data-tooltip-id="offer_scoring_info"
    >
      <div className={classNames(style.scoreLabel__score, style[offer.scoreLabel])}>
        <strong>{offer.score}</strong>
        <span>/10</span>
      </div>
      <div className={classNames(style.scoreLabel__name, style[offer.scoreLabel])}>
        {t(`${offer.scoreLabel}_offer`)}
      </div>
    </div>
  );
};

export default memo(ScoreLabel);

import React, { FC, useState } from 'react';

import Price from 'components/common/price';
import { StationType } from 'src/stations/types';
import CarIcon from 'components/common/car-icon';
import { stationTypesToIcon } from 'src/stations/station-tools';
import classNames from 'classnames';
import styles from '../stations-map.module.scss';
import { PigeonProps } from '../types';

interface Props extends PigeonProps {
  locationTypes: Array<StationType>;
  priceDiff?: number;
  price?: number;
  currency: string;
  current?: boolean;
  highlight?: boolean;
  zIndex?: number;
  onClick?: () => void;
  onHoverChange?: (e: boolean) => void;
  markerLabelOnHover?: string;
}

const CarPriceMarker: FC<Props> = ({
  locationTypes,
  currency,
  onClick = () => null,
  onHoverChange = () => null,
  priceDiff = undefined,
  price = undefined,
  zIndex = undefined,
  current = false,
  highlight = false,
  markerLabelOnHover = undefined,
  ...props
}) => {
  const [hover, setHover] = useState(false);

  const changeHover = (e: boolean) => {
    setHover(e);
    if (!onHoverChange) return;
    onHoverChange(e);
  };

  return (
    <div
      className={styles.carVariantMarker}
      style={{
        left: `${props.left}px`,
        top: `${props.top}px`,
        zIndex: zIndex
      }}
      onClick={onClick}
      onMouseEnter={() => changeHover(true)}
      onMouseLeave={() => changeHover(false)}
    >
      <div
        className={classNames(styles.carVariantMarker__label, {
          [styles['carVariantMarker__label--green']]: current,
          [styles['carVariantMarker__label--yellow']]: highlight
        })}
      >
        <CarIcon
          icon={stationTypesToIcon(locationTypes)}
          size="16px"
          className={styles.carVariantMarker__icon}
        />
        {markerLabelOnHover && hover ? (
          <span className={styles.carVariantMarker__hoverLabel}>{markerLabelOnHover}</span>
        ) : (
          <>
            {priceDiff !== undefined && priceDiff >= 0 && <span>+</span>}
            <Price price={price || priceDiff} currency={currency} round />
          </>
        )}
      </div>
    </div>
  );
};

export default CarPriceMarker;

import autofillData from 'mock/autifill-data';
import { get } from 'lodash';
import { ServerResponse } from 'http';
import getConfig from 'next/config';
import { RedisCache } from 'src/redis-cache';
import { prepareDropOffDate, preparePickUpDate, dayDiff } from 'src/utils/date-time';
import { Agent } from 'types/agent/agent.type';
import { AutofillData } from 'types/booking/autofill-data.type';
import { ResourcesNamespace } from 'types/engine/resources-namespace.type';
import SubpageType from 'types/engine/subpage-type.type';
import { SearchData } from 'types/search/search-data.type';
import { AxiosResponse } from 'axios';
import { Location } from 'components/suggester/types';
import moment from 'moment';
import { CustomHeaders } from 'types/agent/custom-headers.type';
import { GeneralConfig } from 'types/engine/general-config.type';
import { defaultCustomHeaders, defaultCustomTracking } from 'src/config';
import { CustomTracking } from 'types/agent/custom-tracking.type';
import { ApiResponse } from 'types/engine/api';
import { firstUrlParamValue, proxyUrl } from 'src/utils';
import FrontendError from 'src/frontend-error.class';
import ErrorCodes from 'types/error-codes.enum';
import { SessionStatus } from 'types';
import axiosInstance from 'src/axios-instance';
import simpleLog from 'src/logger/simple-log';
import importTranslations from './translations';

const { publicRuntimeConfig } = getConfig();
const LOCATION_TTL = 3600;
const COUNTRIES_TTL = 3600;
const HEADERS_TTL = 3600;
const API_URL = publicRuntimeConfig.apiUrl;

// Define additional data needed for specific subpages
export type SubpagesData = {
  translations: Array<ResourcesNamespace>;
  countries: any;
  customHeaders: CustomHeaders;
  customTracking: CustomTracking;
  search?: SearchData;
  searchid?: string;
  oid?: string;
  autofill?: AutofillData;
  confirmation?: any;
  sessionStatus?: SessionStatus;
};

// Fetch location from suggester
const fetchLocationById = (id: string, locale: string, customProxy = ''): Promise<Location> =>
  new Promise((resolve, reject) => {
    const requestParams = {
      locale,
      phrase: id
    };
    axiosInstance.post(`${proxyUrl(customProxy)}suggester`, requestParams).then((res) => {
      if (!Array.isArray(res.data) || res.data.length === 0) {
        reject();
        simpleLog({
          level: 'WARNING',
          tag: 'emptySuggesterResponse',
          group: 'suggester',
          msg: `Cannot resolve location: ${id}`,
          data: {
            location: id
          }
        });
        return;
      }
      if (['PCLI', 'PCLIX'].includes(res.data[0]?.locationType)) {
        reject();
        simpleLog({
          level: 'WARNING',
          tag: 'forbiddenLocation',
          group: 'suggester',
          msg: `Forbidden location type: ${res.data[0]?.locationType}`,
          data: {
            location: id
          }
        });
        return;
      }

      resolve({
        locationKey: get(res.data, '[0].locationKey', ''),
        name: get(res.data, '[0].location', ''),
        center: {
          latitude: get(res.data, '[0].locationLat', 0),
          longitude: get(res.data, '[0].locationLon', 0)
        }
      });
    }, reject);
  });

// Get location data from redis or suggester
export const getLocationById = (
  id: string,
  locale: string,
  redis?: RedisCache,
  customProxy = ''
): Promise<Location> =>
  new Promise((resolve, reject) => {
    if (!id) {
      reject();
      return;
    }
    const fetch = () => fetchLocationById(id, locale, customProxy);
    if (!redis) {
      fetch().then(resolve, reject);
    } else {
      redis.getOrFetch(`location:${id}`, fetch, LOCATION_TTL).then(resolve, reject);
    }
  });

// Fetch countries
const fetchCountries = (locale: string): Promise<any> =>
  new Promise((resolve, reject) => {
    const requestParams = {
      locale: `${locale}`
    };
    axiosInstance
      .post(`${API_URL}countries`, requestParams)
      .then((res: AxiosResponse<ApiResponse>) => {
        resolve(res.data);
      }, reject);
  });

// Get contries from redis or api
const getCountries = (locale: string, redis?: RedisCache): Promise<any> =>
  new Promise((resolve, reject) => {
    const fetch = () => fetchCountries(locale);
    if (!redis) {
      fetch().then(resolve, reject);
    } else {
      redis.getOrFetch(`countries:${locale}`, fetch, COUNTRIES_TTL).then(resolve, reject);
    }
  });

const fetchCustomHeaders = (
  aid: string,
  locale: string,
  headersPreviewToken = ''
): Promise<CustomHeaders> =>
  new Promise((resolve) => {
    if (headersPreviewToken) {
      axiosInstance
        .post(`${API_URL}headerspreview/cached?previewToken=${headersPreviewToken}`)
        .then(
          (res) => {
            resolve(get(res, 'data', defaultCustomHeaders));
          },
          () => resolve(defaultCustomHeaders)
        );
    } else {
      const postData = {
        source: '',
        agentId: aid,
        locale
      };
      axiosInstance.post(`${API_URL}headers`, postData).then(
        (res) => {
          resolve(get(res, 'data', defaultCustomHeaders));
        },
        () => resolve(defaultCustomHeaders)
      );
    }
  });

const getCustomHeaders = (
  aid: string,
  locale: string,
  redis?: RedisCache,
  mobile = false,
  headersPreviewToken = ''
): Promise<CustomHeaders> =>
  new Promise((resolve, reject) => {
    const fetch = () => fetchCustomHeaders(aid, locale, headersPreviewToken);
    if (!redis || headersPreviewToken) {
      fetch().then(resolve, reject);
    } else {
      redis
        .getOrFetch(`headers:${aid}:${locale}:${mobile ? 'mobile' : 'desktop'}`, fetch, HEADERS_TTL)
        .then(resolve, reject);
    }
  });

const fetchCustomTracking = (aid: string): Promise<CustomTracking> =>
  new Promise((resolve) => {
    const requestParams = {
      agentId: aid
    };
    axiosInstance.post(`${API_URL}tracking`, requestParams).then(
      (res) => {
        resolve(get(res, 'data', defaultCustomTracking));
      },
      () => resolve(defaultCustomTracking)
    );
  });

const getCustomTracking = (
  aid: string,
  redis?: RedisCache,
  mobile = false
): Promise<CustomTracking> =>
  new Promise((resolve, reject) => {
    const fetch = () => fetchCustomTracking(aid);
    if (!redis) {
      fetch().then(resolve, reject);
    } else {
      redis
        .getOrFetch(`tracking:${aid}:${mobile ? 'mobile' : 'desktop'}`, fetch, HEADERS_TTL)
        .then(resolve, reject);
    }
  });

// Init search data
const initSearch = async (
  res: ServerResponse,
  locale: string,
  driverCountry: string,
  redis?: RedisCache
): Promise<SearchData> => {
  const { aid, pckloc, pckdate, pcktime, drploc, drpdate, drptime } = res['req']['query'];
  const age = Number(firstUrlParamValue(get(res, 'req.query.driver_age', '35')));
  const fromDate = preparePickUpDate(pckdate || '', pcktime || '');
  const toDate = prepareDropOffDate(drpdate || '', drptime || '', fromDate);
  const data = {
    agentId: aid,
    location: {
      pickUp: pckloc ? await getLocationById(pckloc, locale, redis).catch(() => null) : null,
      dropOff: drploc ? await getLocationById(drploc, locale, redis).catch(() => null) : null
    },
    dates: {
      from: fromDate,
      to: toDate
    },
    rentDays: dayDiff(moment(fromDate).hour(0).minute(0), moment(toDate).hour(0).minute(0)),
    driverAge: age >= 18 && age < 100 ? age : 35,
    driverCountry,
    locale
  };
  return data;
};

// Init confirmation data
const initConfirmation = (res: ServerResponse, locale: string): Promise<any> => {
  const userKey = firstUrlParamValue(get(res, 'req.query.userkey', ''));
  if (!userKey) return Promise.resolve(null);

  return new Promise((resolve) => {
    axiosInstance.post<ApiResponse>(`${API_URL}confirmation`, { userKey, locale }).then(
      (response) => {
        resolve({
          ...get(response, 'data', {}),
          requestId: get(response, 'requestId', ''),
          correctKey: true
        });
      },
      (err: FrontendError) => {
        resolve(
          err.code === ErrorCodes.confirmationNeedsCredentials
            ? {
                loginRequired: true,
                userKey,
                requestId: err.requestId,
                correctKey: true
              }
            : {
                requestId: err.requestId,
                correctKey: false
              }
        );
      }
    );
  });
};

export const getSessionStatus = (sessionId: string): Promise<SessionStatus> => {
  const emptySession = {
    sessionId: '',
    ttl: 0
  };
  if (!sessionId) return Promise.resolve(emptySession);
  return new Promise((resolve) => {
    axiosInstance.post(`${API_URL}session-status`, { sessionId }).then(
      (res) => {
        resolve({
          sessionId,
          ttl: res.data?.ttl
        });
      },
      () => resolve(emptySession)
    );
  });
};

// Get data for subpages
export const prepareSubpagesData = (
  res: ServerResponse,
  subpage: SubpageType,
  agent: Agent,
  general: GeneralConfig,
  langNss: Array<string>,
  redis?: RedisCache
): Promise<SubpagesData> =>
  new Promise((resolve, reject) => {
    Promise.all([
      importTranslations(agent, general.locale, langNss, redis),
      getCountries(general.locale, redis),
      initSearch(res, general.locale, general.cor, redis),
      getCustomHeaders(
        agent.agentId,
        general.locale,
        redis,
        general.ssrIsMobile,
        get(res, 'req.query.headersPreviewToken', '')
      ),
      getCustomTracking(agent.agentId, redis, general.ssrIsMobile),
      initConfirmation(res, general.locale),
      getSessionStatus(get(res, 'req.query.searchid', ''))
    ]).then((data) => {
      resolve({
        translations: data[0],
        countries: data[1],
        customHeaders: data[3],
        customTracking: data[4],
        search: data[2],
        oid: firstUrlParamValue(get(res, 'req.query.oid', '')),
        searchid: firstUrlParamValue(get(res, 'req.query.searchid', '')),
        autofill: subpage === 'bookingForm' ? autofillData : null,
        confirmation: data[5],
        sessionStatus: data[6]
      });
    }, reject);
  });

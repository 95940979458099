import React, { FC, useState } from 'react';
import Button from 'components/forms/button';
import useTranslation from 'providers/translations/use-translations';

type Props = {
  labelOpened?: string;
  labelClosed?: string;
  isOpened?: boolean;
  className?: string;
  onClick?: (currentValue) => void;
};

const BoxOpener: FC<Props> = ({
  labelOpened = '',
  labelClosed = '',
  isOpened = true,
  onClick = () => false,
  className = ''
}) => {
  const [opened, setValue] = useState(isOpened);
  const { t } = useTranslation();

  const currentLabel = (opened && (labelOpened || t('hide'))) || labelClosed || t('show');
  const currentIcon = (opened && 'chevron-up') || 'chevron-down';

  const toggleBox = () => {
    onClick(!opened);
    setValue(!opened);
  };

  return (
    <span className={className}>
      <Button
        size="small"
        text="{currentLabel}"
        icon={currentIcon}
        variant="text"
        onClick={() => toggleBox()}
      />
    </span>
  );
};

export default BoxOpener;

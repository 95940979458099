import React, { FC, memo, useEffect, useState } from 'react';
import useCarsApi from 'providers/cars-api/use-cars-api';
import useTranslation from 'providers/translations/use-translations';
import { CarOffer } from 'src/cars/types';
import Checkbox from 'components/forms/checkbox';

import styles from './compare.module.scss';

interface Props {
  offer: CarOffer;
}

const CompareBtn: FC<Props> = ({ offer }) => {
  const { t } = useTranslation();
  const { compare, addToCompare, removeFromCompare } = useCarsApi();
  const [checked, setChecked] = useState(false);

  const toggleCompare = (state: boolean) => {
    setChecked(state);
    if (state) {
      addToCompare(offer);
    } else {
      removeFromCompare(offer.offerId);
    }
  };

  useEffect(() => {
    const newState = compare.some((el) => el.offerId === offer.offerId);
    if (newState !== checked) {
      setChecked(newState);
    }
  }, [checked, compare]);

  return (
    <div className={styles.compareBtn}>
      <Checkbox label={t('drel_to_compare')} value={checked} reverse onChange={toggleCompare} />
    </div>
  );
};

export default memo(CompareBtn);

import { BehaviorSubject } from 'rxjs';
import { get } from 'lodash';
import scaValidate from 'src/sca';
import { allSubjects } from 'src/utils';
import { TestMode } from 'types/engine/dev-data.type';
import { overwriteParams } from 'src/utils/data-conversion';
import { ScaResult } from 'src/sca/types';
import ErrorCodes from 'types/error-codes.enum';
import FrontendError from 'src/frontend-error.class';
import {
  prepareExtrasToSend,
  prepareAddonsToSend,
  preparePaymentDataToSend,
  preparePersonalDataToSend,
  prepareScaToSend,
  prepareInsurancesToSend
} from './helpers';
import { getFormConfig, makeBooking } from './requests';
import {
  BookingData,
  BookingEngineEvent,
  BookingResult,
  FormConfigData,
  ScaAuthParams,
  StationsData
} from './types';

type ConstructParams = {
  searchId: string;
  offerId: string;
  driverAge: number;
  driverCountry: string;
  locale: string;
  testMode: TestMode;
};

class BookingEngine {
  events: BehaviorSubject<BookingEngineEvent>;

  searchId: string;

  offerId: string;

  locale: string;

  // offer: CarOffer;

  driverAge: number;

  driverCountry: string;

  testMode: TestMode;

  stations: StationsData;

  constructor(params: ConstructParams) {
    Object.assign(this, params);
    this.events = new BehaviorSubject<BookingEngineEvent>(null);
  }

  loadFormConfig(changes: any = {}, reprice = false, customProxy = ''): Promise<FormConfigData> {
    return new Promise((resolve, reject) => {
      getFormConfig(
        this.searchId,
        this.offerId,
        this.locale,
        this.testMode,
        changes,
        customProxy
      ).then((data) => {
        if (!data || data.stations.length === 0) {
          reject(
            new FrontendError([{ code: ErrorCodes.offerNotFound, message: 'Offer not found' }])
          );
          return;
        }
        this.events.next({
          type: reprice ? 'reprice' : 'refresh',
          data: {
            formConfig: data
          }
        });
        resolve(data);
      }, reject);
    });
  }

  processBooking(bookingData: BookingData, customProxy = ''): Promise<BookingResult> {
    return new Promise((resolve, reject) => {
      this.offerId = bookingData.offer.offerId;
      let bookingId = 0;
      let scaResult: ScaResult;

      this.events.next({ type: 'start' });
      const preBookingSteps = [];

      const runScaValidation =
        bookingData.payment.usedPayment === 'CC' &&
        bookingData.dev.disableSca !== true &&
        bookingData.hasSCA === true;

      if (runScaValidation) {
        preBookingSteps.push(scaValidate(this.prepareScaParams(bookingData)));
      }

      allSubjects<BookingEngineEvent>(preBookingSteps).subscribe({
        next: (e) => {
          this.events.next(e);

          switch (e.type) {
            case 'scaInit':
              bookingId = Number(get(e.data, 'scaConfig.bookingId', '0'));
              break;

            case 'scaVerified':
              scaResult = e.data.scaResult;
              break;

            default:
          }
        },
        complete: () => {
          makeBooking(
            {
              sessionId: this.searchId,
              bookingId,
              offerId: this.offerId,
              productId: '',
              customer: preparePersonalDataToSend(bookingData.customer),
              driver: preparePersonalDataToSend(
                overwriteParams<any>(bookingData.driver, bookingData.customer)
              ),
              payment: preparePaymentDataToSend(bookingData.payment),
              sca: runScaValidation ? prepareScaToSend(scaResult) : undefined,
              addons: prepareAddonsToSend(bookingData.addons),
              extras: prepareExtrasToSend(bookingData.extras),
              insurances: prepareInsurancesToSend(bookingData.insurances),
              totalPrice: bookingData.offer.price,
              signature: '',
              locale: bookingData.locale,
              frontend: window ? window.location.hostname : '',
              testMode: this.testMode
            },
            customProxy
          ).then(
            (res) => {
              if (res.userKey) {
                resolve(res);
              } else {
                reject(
                  new FrontendError([{ code: ErrorCodes.frontendHell, message: 'No user key' }])
                );
                this.events.next({ type: 'fail' });
              }
            },
            (err) => {
              reject(err);
              this.events.next({ type: 'fail' });
            }
          );
        },
        error: (err) => {
          reject(err);
          this.events.next({ type: 'fail' });
        }
      });
    });
  }

  /* ------------------------------------------------------------------- PAYLOADS PREPARATION --- */

  prepareScaParams(data: BookingData): ScaAuthParams {
    const { deposit, prepaid, price } = data.offer;
    return {
      sessionId: this.searchId,
      offerId: this.offerId,
      productId: '',
      customer: preparePersonalDataToSend(data.customer),
      payment: preparePaymentDataToSend(data.payment),
      totalPrice: prepaid && deposit && deposit < price ? deposit : price,
      currency: data.offer.currency,
      testMode: this.testMode,
      frontend: window ? window.location.hostname : ''
    };
  }
}

export default BookingEngine;

import axiosInstance from 'src/axios-instance';
import { TestMode } from 'types/engine/dev-data.type';
import { proxyUrl } from 'src/utils';
import { BookingResult, FormConfigData, MakeBookingParams } from './types';

export const getFormConfig = (
  searchId: string,
  oid: string,
  locale: string,
  testMode?: TestMode,
  changes: any = {},
  customProxy = ''
): Promise<FormConfigData> =>
  new Promise((resolve, reject) => {
    const postData = {
      sessionId: searchId,
      offerId: oid,
      locale,
      testMode,
      ...changes
    };

    axiosInstance.post(`${proxyUrl(customProxy)}form`, postData).then((res) => {
      resolve(res.data);
    }, reject);
  });

export const makeBooking = (data: MakeBookingParams, customProxy = ''): Promise<BookingResult> =>
  new Promise((resolve, reject) => {
    axiosInstance.post(`${proxyUrl(customProxy)}booking`, data).then((res) => {
      resolve({
        ...res.data,
        requestId: (res as any).requestId
      });
    }, reject);
  });

import turfBbox from '@turf/bbox';
import { featureCollection as turfFeatureCollection, point as turfPoint } from '@turf/helpers';
import geoViewport from '@mapbox/geo-viewport';
import { Point } from 'pigeon-maps';
import { Station } from 'src/stations/types';
import { StationType } from 'src/stations/types';
import { FitMapResult, StationData } from './types';

export const stationHasCoords = (station: Station | StationData): boolean => station.geo !== null;

export const fitMap = (
  coordinates: Array<Point>,
  width = 920,
  height = 500
): FitMapResult | null => {
  const points = coordinates
    .filter((point) => point !== null)
    .map((point) => turfPoint([point[1], point[0]]));

  if (points.length === 0) return null;

  const features = turfFeatureCollection(points);
  const bounds = turfBbox(features);

  const { center, zoom } = geoViewport.viewport(bounds, [width, height]);

  return {
    center: [center[1], center[0]],
    zoom: Math.min(zoom - 0.5, 13)
  };
};
export const getStationMarkerIconPath = (type: StationType): string => {
  switch (type) {
    case 'terminal':
    case 'shuttle':
    case 'airport':
      return `M25.3,33.1c0.3-0.5,0.8-1.7,0.9-2.4c0.2-4.2,0.4-10.6,0.5-12.2c0.5-0.4,1.2-0.8,1.8-1.3
      c7.3-5.2,6.8-7.3,6.5-7.8c-0.4-0.5-2.2-1.6-9.5,3.5c-0.6,0.5-1.2,0.9-1.8,1.3c-1.4-0.5-7.5-2.5-11.4-3.7c-0.6-0.2-1.9-0.1-2.5,0.1
      c0,0-1.3,0.3-0.1,1.3c1.4,1.2,5.9,5.2,7.9,6.9c-1.7,1.2-3.4,2.7-4.7,4.2c-2.1-0.2-5.9-0.4-6.6,0c-1.1,0.6-1.2,0.9-0.1,1.5
      s4.4,2.5,4.4,2.5s0.6,3.8,0.7,5c0.2,1.2,0.5,1.3,1.4,0.4c0.6-0.5,1.7-4.3,2.3-6.3c1.8-0.7,3.7-1.8,5.4-3c0.9,2.4,3.1,8.1,3.7,9.8
      C24.7,34.4,25.4,33.2,25.3,33.1z`;

    case 'rail':
      return `M17.7,5.5c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S18.5,5.5,17.7,5.5z M23.3,5.5
      c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S24.1,5.5,23.3,5.5z M30.5,9.8v17c0,0.8-0.6,1.4-1.4,1.4h-1.4L29,32
      c0.2,0.8-0.1,1.5-0.9,1.8c-0.1,0-1.2,0.3-1.8-0.9l-0.1-0.5H14.9l-0.1,0.5c-0.2,0.6-0.9,1.2-1.8,0.9c-0.8-0.2-1.2-1-0.9-1.8l1.3-3.9
      H12c-0.8,0-1.4-0.6-1.4-1.4v-17c0-0.8,0.6-1.4,1.4-1.4h17C29.8,8.4,30.4,9,30.5,9.8z M15.5,25.4c1.1,0,2.1-1,2.1-2.1
      s-0.9-2.1-2.1-2.1s-2.2,1-2.2,2.1C13.4,24.4,14.4,25.4,15.5,25.4z M25.2,29.7l-0.5-1.4h-8.4l-0.5,1.4H25.2z M27.6,23.3
      c0-1.2-1-2.1-2.1-2.1c-1.1,0-2.1,1-2.1,2.1s1,2.1,2.1,2.1C26.7,25.4,27.6,24.5,27.6,23.3z M27.6,17.7v-5.9c0-0.3-0.2-0.6-0.5-0.6H14
      c-0.3,0-0.6,0.2-0.6,0.6v5.9c0,0.3,0.2,0.6,0.6,0.6h13C27.3,18.3,27.6,18,27.6,17.7L27.6,17.7z`;

    default:
      return `M12.3,10.6v21.5H11v2.2h19V32h-1.3V10.5h-1.2V8.3h-1.4V6H15v2.2h-1.4v2.3H12.3z M24.5,29.4v-2h2v2H24.5z
      M24.5,25v-2h2v2H24.5z M24.5,20.7v-2h2v2H24.5z M24.5,16.3v-2h2v2H24.5z M19.6,32.1v-4.7h2v4.7H19.6z M19.6,25v-2h2v2H19.6z
      M19.6,20.7v-2h2v2H19.6z M19.6,16.3v-2h2v2H19.6z M14.6,29.4v-2h2v2H14.6z M14.6,25v-2h2v2H14.6z M14.6,20.7v-2h2v2H14.6z
      M14.6,16.3v-2h2v2H14.6z`;
  }
};

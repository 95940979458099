import classNames from 'classnames';
import CarIcon from 'components/common/car-icon';
import Price from 'components/common/price';
import Button from 'components/forms/button';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';
import React, { FC } from 'react';
import { goToBookingPage } from 'src/url-manager';
import { CarOffer, CarVariant as CarVariantType } from 'src/cars/types';
import styles from '../details.module.scss';
import VariantStationInfo from './variant-station-info';

type Props = {
  variant: CarVariantType;
  offer: CarOffer;
  current?: boolean;
  splitStations?: boolean;
  mode?: 'pickup' | 'dropoff';
  hideBtn?: boolean;
  highlight?: boolean;
};

const CarVariant: FC<Props> = ({
  variant,
  offer,
  current = false,
  splitStations = false,
  mode = 'pickup',
  hideBtn = false,
  highlight = false
}) => {
  const { inhouse } = useDevToolsContext();
  const oneway = variant.pickupStation.code !== variant.dropoffStation.code;
  const station = mode === 'pickup' ? 'pickupStation' : 'dropoffStation';
  return (
    <>
      <div className={styles.stationsTab__variant__stations}>
        <VariantStationInfo
          station={variant[station]}
          icon="a"
          oneway={splitStations && oneway}
          current={current}
        />
        {splitStations && oneway && (
          <VariantStationInfo
            station={variant.dropoffStation}
            icon="b"
            oneway={oneway}
            current={current}
          />
        )}
      </div>

      {!hideBtn && (
        <Button
          className={classNames(styles.stationsTab__bookBtn, {
            [styles['stationsTab__bookBtn--highlight']]: highlight
          })}
          variant="primary"
          onClick={() => {
            goToBookingPage(variant.offerId, inhouse);
          }}
        >
          <Price price={variant.price} currency={offer.currency} />
          <CarIcon icon="chevron-right" />
        </Button>
      )}
    </>
  );
};

export default CarVariant;

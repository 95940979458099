import React, { FC } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Discount } from 'types/car-discount.type';
import useTranslation from 'providers/translations/use-translations';
import Price from 'components/common/price';
import { useRouter } from 'next/router';
import moment from 'moment';
import style from './offer.module.scss';

type Props = {
  discount: Discount;
};

const DiscountLabel: FC<Props> = ({ discount }) => {
  const { t } = useTranslation();
  const router = useRouter();

  const getPopupContent = () =>
    ReactDOMServer.renderToStaticMarkup(
      <div className={style.discountLabel__conditions}>
        <ul>
          <li className={style.discountLabel__conditions_label}>{t('discount_conditions')}</li>
          <li>
            {`${t('discount_book_between')}`}
            <br />
            {`
              ${moment(discount.bookingFrom).locale(router.locale).format('D. MMM YYYY')}
              -
              ${moment(discount.bookingTo).locale(router.locale).format('D. MMM YYYY')}
            `}
          </li>
          <li>
            {`${t('discount_rental_start_between')}`}
            <br />
            {`
              ${moment(discount.rentalFrom).locale(router.locale).format('D. MMM YYYY')}
              -
              ${moment(discount.rentalTo).locale(router.locale).format('D. MMM YYYY')}
            `}
          </li>
          <li>{`${t('discount_min_rental_period')}: ${discount.minRentalPeriod} ${t('days')}`}</li>
          <li className={style.discountLabel__conditions_gain}>
            {`${t('discount_your_gain')}:`}
            <Price
              className={style.discountLabel__conditions_price}
              price={discount.amount}
              currency={discount.unit === 'percentage' ? '%' : discount.currency}
            />
          </li>
          <li className={style.discountLabel__conditions_desc}>{t('discount_description')}</li>
        </ul>
      </div>
    );

  if (getPopupContent() === undefined) return null;
  return (
    <div className={style.discountLabel}>
      <div
        className={style.discountLabel__inner}
        data-html
        data-tooltip-html={`<div>${getPopupContent()}</div>`}
        data-tooltip-id="offer_discount_info"
      >
        <span className={style.discountLabel__upperLabel}>{t('discount_code')}</span>
        <span className={style.discountLabel__code}>{discount.code}</span>
      </div>
    </div>
  );
};

export default DiscountLabel;

import React, { FC, memo } from 'react';
import CarIcon from './car-icon';

import styles from './common.module.scss';

type Props = {
  title: string;
  subtitle: string;
  icon: string;
};

const IcoLabel: FC<Props> = (props) => (
  <div className={styles.icoLabel}>
    <div className={styles.icoLabel__content}>
      <span className={styles.icoLabel__title}>{props.title}</span>
      <span className={styles.icoLabel__subtitle}>{props.subtitle}</span>
    </div>
    <CarIcon icon={props.icon} size="24px" className={styles.icoLabel__icon} />
  </div>
);

export default memo(IcoLabel);

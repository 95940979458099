import moment, { Moment } from 'moment';

export const priceFormat1 = (price: number) => {
  try {
    return Math.ceil(price);
  } catch {
    return price;
  }
};

export const priceFormat2 = (price: number) => {
  try {
    return price.toFixed(2).replace('.', '');
  } catch {
    return price;
  }
};

export const priceFormat3 = (price: number) => {
  try {
    return price.toFixed(2).replace('.', ',');
  } catch {
    return price;
  }
};

export const jaDateFormat = (date: string | Moment) => {
  try {
    return moment(date).format('YYYY-MM-DD');
  } catch {
    return date;
  }
};

export const euDateFormat = (date: string | Moment) => {
  try {
    return moment(date).format('DD.MM.YYYY');
  } catch {
    return date;
  }
};

export const usDateFormat = (date: string | Moment) => {
  try {
    return moment(date).format('MM/DD/YYYY');
  } catch {
    return date;
  }
};

export const defaultDateFormat = (date: string | Moment) => {
  try {
    return moment(date).format('DDMMYYYY');
  } catch {
    return date;
  }
};

export const priceFormat4 = (price: number) => price.toFixed(2);

import { cloneDeep } from 'lodash';
import moment from 'moment';
import { BookignScaData, ScaResult } from 'src/sca/types';
import {
  BookingExtrasItem,
  BookingAddonsItem,
  BookingInusrancesItem,
  BookingPaymentData,
  BookingPersonalData,
  FormConfigData,
  SelectedAddons,
  SelectedInsurances
} from './types';

export const emptyFormConfig: FormConfigData = {
  extraFields: [],
  minAge: 0,
  maxAge: 0,
  norris: null,
  offer: null,
  payments: [],
  reloadOn: [],
  hasStateFieldRequired: false,
  stations: null,
  terms: null,
  termsOnDemand: null,
  addonsOnDemand: null,
  showDriverForm: false,
  warnings: []
};

export const preparePersonalDataToSend = (item: BookingPersonalData): any => {
  const person = cloneDeep(item);
  person.street = `${person.street} ${person.houseno}`;
  person.dob = item.dob ? moment(item.dob).format('YYYY-MM-DD') : '';
  return person;
};

export const preparePaymentDataToSend = (item: BookingPaymentData): any => {
  let payment = null;

  if (item.usedPayment === 'CC') {
    payment = cloneDeep(item.creditCard);
    payment.expire = moment(item.creditCard.expire).format('YYYY-MM-DD');
  }

  if (item.usedPayment === 'EP') {
    payment = cloneDeep(item.epPayment);
  }

  if (item.usedPayment === 'BANK') {
    payment = cloneDeep(item.bankPayment);
  }

  if (item.usedPayment === 'ONACC') {
    payment = cloneDeep(item.onAccPayment);
  }

  if (item.usedPayment === 'PP') {
    payment = cloneDeep(item.ppPayment);
  }

  return payment;
};

export const prepareExtrasToSend = (extras: {
  [key: string]: string;
}): Array<BookingExtrasItem> => {
  const result: Array<BookingExtrasItem> = [];
  Object.keys(extras).forEach((key) => {
    result.push({
      key,
      variant: extras[key]
    });
  });
  return result;
};

export const prepareScaToSend = (data: ScaResult): BookignScaData => ({
  success: data.verified.success,
  status: data.status,
  message: data.message,
  transactionId: data.transactionId,
  payId: data.payId,
  verified: {
    success: data.verified.success,
    data: data.verified.data,
    xid: data.verified.xid,
    eci: data.verified.eci,
    dsResponse: data.verified.dsResponse,
    version: data.verified.version,
    date: data.verified.date,
    time: data.verified.time,
    acsTransactionId: data.verified.acsTransactionId
  }
});

export const prepareAddonsToSend = (addons: SelectedAddons): Array<BookingAddonsItem> => {
  const result: Array<BookingAddonsItem> = [];
  addons.forEach((item) => {
    result.push({
      key: item.key,
      variants: item.selection
    });
  });
  return result;
};

export const prepareInsurancesToSend = (
  insurances: SelectedInsurances
): Array<BookingInusrancesItem> => {
  const result: Array<BookingAddonsItem> = [];
  insurances.forEach((item) => {
    result.push({
      key: item.key,
      variants: [item.key]
    });
  });
  return result;
};

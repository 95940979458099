import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import CarIcon from './car-icon';

import styles from './common.module.scss';

type Props = {
  className: string;
  children: Array<ReactElement>;
  circled?: boolean;
};

const TickList: FC<Props> = ({ className, children, circled = false }) => {
  if (!children) return null;

  return (
    <ul className={classNames(styles.tickList, className)}>
      {children.map((item, i) => (
        <li key={i} className={styles.tickList__item}>
          <CarIcon
            icon={item.props['data-icon'] || 'tick'}
            className={classNames(styles.tickList__icon, item.props['data-icon-className'], {
              [styles['tickList__icon--circled']]: circled
            })}
          />
          <div>{item.props.children}</div>
        </li>
      ))}
    </ul>
  );
};

export default TickList;

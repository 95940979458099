import { get } from 'lodash';
import nestedObjectAssign from 'nested-object-assign';
import axiosInstance from 'src/axios-instance';
import { RedisCache } from 'src/redis-cache';
import { proxyUrl } from 'src/utils';
import { Agent } from 'types/agent/agent.type';
import { ResourcesNamespace } from 'types/engine/resources-namespace.type';

const LANG_DEFAULT_TTL = 3600;
const LANG_AGENT_TTL = 3600;

const fetchTranslations = (
  locale: string,
  pages: Array<string>,
  agentId?: string,
  consoId?: string
): Promise<any> =>
  new Promise((resolve) => {
    const requestParams = {
      locale,
      pages,
      agentId: agentId || '',
      consolidatorId: agentId ? consoId : '',
      site: 'NEWCAR_B2C'
    };
    axiosInstance.post(`${proxyUrl()}lang`, requestParams).then(
      (res) => {
        resolve(get(res.data, 'phrases', {}));
      },
      (err) => {
        console.error('Translations request failed', {
          url: `${proxyUrl()}lang`,
          requestParams,
          err
        });
        resolve({});
      }
    );
  });

// Get default translations
const getDefaultTranslations = (
  locale: string,
  pages: Array<string>,
  redis?: RedisCache
): Promise<any> =>
  new Promise((resolve, reject) => {
    const fetch = () => fetchTranslations(locale, pages);
    if (!redis) {
      fetch().then(resolve, reject);
    } else {
      redis
        .getOrFetch(`lang:${locale}:${pages.join(',')}`, fetch, LANG_DEFAULT_TTL)
        .then(resolve, reject);
    }
  });

// Get translations for specified agent
const getAgentTranslations = (
  locale: string,
  pages: Array<string>,
  agentId: string,
  consoId: string,
  redis?: RedisCache
): Promise<any> =>
  new Promise((resolve, reject) => {
    const fetch = () => fetchTranslations(locale, pages, agentId, consoId);
    if (!redis) {
      fetch().then(resolve, reject);
    } else {
      redis
        .getOrFetch(`lang:${locale}:${pages.join(',')}-${agentId}`, fetch, LANG_AGENT_TTL)
        .then(resolve, reject);
    }
  });

const getTranslations = (
  agent: Agent,
  locale: string,
  pages: Array<string>,
  redis?: RedisCache
): Promise<any> =>
  new Promise((resolve, reject) => {
    Promise.all([
      getDefaultTranslations(locale, pages, redis),
      getAgentTranslations(locale, pages, agent.agentId, agent.consolidatorId, redis)
    ]).then((data) => {
      resolve(nestedObjectAssign(data[0], data[1]));
    }, reject);
  });

// Retuns prepared translations
const importTranslations = (
  agent: Agent,
  locale: string,
  namespaces: Array<string> = ['common'],
  redis: RedisCache = undefined
): Promise<Array<ResourcesNamespace>> =>
  new Promise((resolve, reject) => {
    getTranslations(agent, locale, namespaces, redis).then((res) => {
      const data: Array<ResourcesNamespace> = [];
      Object.keys(res).forEach((name) => {
        data.push({
          ns: name,
          resources: res[name]
        });
      });
      resolve(data);
    }, reject);
  });

export default importTranslations;

import React, { memo, useState } from 'react';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import useCarsApi from 'providers/cars-api/use-cars-api';
import useTranslation from 'providers/translations/use-translations';
import Dropdown from 'components/forms/dropdown';
import { DropdownItem } from 'components/forms/types';
import { countriesToOptions } from 'src/utils/data-conversion';

const CountrySelect: React.FC = () => {
  const { t } = useTranslation();
  const { countries, general, changeCor } = useCarsApi();
  const { isMobile } = useScreenDetect();
  const [countryOptions] = useState<Array<DropdownItem>>(countriesToOptions(countries));

  const handleCorChanged = ({ value }: DropdownItem) => {
    changeCor(value);
  };

  return (
    <Dropdown
      label={`${t('0nc_country_residence')}:`}
      options={countryOptions}
      value={countryOptions.find(({ value }) => value === general.cor)}
      onChange={handleCorChanged}
      noEmptyOption
      searchEnabled
      horizontal={isMobile ? undefined : true}
      noBorder
    />
  );
};

export default memo(CountrySelect);

import React, { FC, memo } from 'react';
import { CarOffer } from 'src/cars/types';
import CarIcon from 'components/common/car-icon';
import useTranslation from 'providers/translations/use-translations';
import styles from './offer.module.scss';

type Props = {
  offer: CarOffer;
};

const OfferAdvantages: FC<Props> = ({ offer }) => {
  const { t } = useTranslation();
  const { partner } = offer;

  const getPopupContent = () => {
    const content = t(`partner_${partner}_offer_advantages_details`);
    return content === `partner_${partner}_offer_advantages_details` ? undefined : content;
  };

  const getLabel = () => {
    const content = t(`partner_${partner}_offer_advantages_label`);
    return content;
  };

  if (getPopupContent() === undefined) return null;
  return (
    <div className={styles.offerAdvantages}>
      <div
        className={styles.offerAdvantages__badge}
        data-html
        data-tooltip-html={`<div>${getPopupContent()}</div>`}
        data-tooltip-id="offer_advantages_info"
      >
        <CarIcon icon="info" />
        {getLabel()}
      </div>
    </div>
  );
};

export default memo(OfferAdvantages);
